import { Trans } from '@lingui/macro'
import { useLocationLinkProps } from 'hooks/useLocationLinkProps'
import { useMemo } from 'react'
import styled from 'styled-components'
import { ExternalLink, ThemedText } from 'theme/components'

const Container = styled(ThemedText.SubHeaderSmall)`
  opacity: ${({ theme }) => theme.opacity.hover};
  :hover {
    opacity: 1;
  }
  margin-top: 1rem !important;
`

export function ZicoHoldersOnly() {
  return (
    <Container>
      <Trans>
        ZicoSwap available only for{' '}<ExternalLink href='http://zicodao.io'>ZicoDAO</ExternalLink>{' '}holders.
      </Trans>
    </Container>
  )
}
