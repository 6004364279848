import { ChainId, SUPPORTED_CHAINS, SupportedChainsType } from "./chains"

type AddressMap = { [chainId: number]: string }

type ChainAddresses = {
    v3CoreFactoryAddress: string
    multicallAddress: string
    quoterAddress: string
    v3MigratorAddress?: string
    nonfungiblePositionManagerAddress?: string
    tickLensAddress?: string
    swapRouter02Address?: string
    v1MixedRouteQuoterAddress?: string
}

type ChainConfig = {
  router: string
  creationBlock: number
  weth: string
}

const DEFAULT_NETWORKS = [ChainId.POLYGON]

function constructSameAddressMap(address: string, additionalNetworks: ChainId[] = []): AddressMap {
    return DEFAULT_NETWORKS.concat(additionalNetworks).reduce<AddressMap>((memo, chainId) => {
      memo[chainId] = address
      return memo
    }, {})
}

export const PERMIT2_ADDRESS = '0xc424E9015006C252611f05Efc3F75d0B9e7B7ef8'

const WETH_NOT_SUPPORTED_ON_CHAIN = '0x0000000000000000000000000000000000000000'

const CHAIN_CONFIGS: { [key: number]: ChainConfig } = {
  // mainnet
  [1]: {
    router: '0x3fC91A3afd70395Cd496C647d5a6CC9D4B2b7FAD',
    weth: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    creationBlock: 17143817,
  },
  // goerli
  [5]: {
    router: '0x3fC91A3afd70395Cd496C647d5a6CC9D4B2b7FAD',
    weth: '0xb4fbf271143f4fbf7b91a5ded31805e42b2208d6',
    creationBlock: 8940568,
  },
  // sepolia
  [11155111]: {
    router: '0x3fC91A3afd70395Cd496C647d5a6CC9D4B2b7FAD',
    weth: '0xfFf9976782d46CC05630D1f6eBAb18b2324d6B14',
    creationBlock: 3543575,
  },
  // polygon
  [137]: {
    router: '0xb110B75BA1Aa297B8b608008aA1F33b230AE68C0',
    weth: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    creationBlock: 53005067,
  },
  //polygon mumbai
  [80001]: {
    router: '0x3fC91A3afd70395Cd496C647d5a6CC9D4B2b7FAD',
    weth: '0x9c3C9283D3e44854697Cd22D3Faa240Cfb032889',
    creationBlock: 35176052,
  },
  //optimism
  [10]: {
    router: '0xCb1355ff08Ab38bBCE60111F1bb2B784bE25D7e8',
    weth: '0x4200000000000000000000000000000000000006',
    creationBlock: 114702266,
  },
  // optimism goerli
  [420]: {
    router: '0x3fC91A3afd70395Cd496C647d5a6CC9D4B2b7FAD',
    weth: '0x4200000000000000000000000000000000000006',
    creationBlock: 8887728,
  },
  // arbitrum
  [42161]: {
    router: '0x5E325eDA8064b456f4781070C0738d849c824258',
    weth: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
    creationBlock: 169472836,
  },
  // arbitrum goerli
  [421613]: {
    router: '0x3fC91A3afd70395Cd496C647d5a6CC9D4B2b7FAD',
    weth: '0xe39Ab88f8A4777030A534146A9Ca3B52bd5D43A3',
    creationBlock: 18815277,
  },
  // celo
  [42220]: {
    router: '0x643770e279d5d0733f21d6dc03a8efbabf3255b4',
    weth: WETH_NOT_SUPPORTED_ON_CHAIN,
    creationBlock: 21407637,
  },
  // celo alfajores
  [44787]: {
    router: '0x3fC91A3afd70395Cd496C647d5a6CC9D4B2b7FAD',
    weth: WETH_NOT_SUPPORTED_ON_CHAIN,
    creationBlock: 17566658,
  },
  // binance smart chain
  [56]: {
    router: '0x4Dae2f939ACf50408e13d58534Ff8c2776d45265',
    weth: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    creationBlock: 35160263,
  },
  // avalanche
  [43114]: {
    router: '0x4Dae2f939ACf50408e13d58534Ff8c2776d45265',
    weth: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
    creationBlock: 40237257,
  },
  // base goerli
  [84531]: {
    router: '0xd0872d928672ae2ff74bdb2f5130ac12229cafaf',
    weth: '0x4200000000000000000000000000000000000006',
    creationBlock: 6915289,
  },
  // base mainnet
  [8453]: {
    router: '0x3fC91A3afd70395Cd496C647d5a6CC9D4B2b7FAD',
    weth: '0x4200000000000000000000000000000000000006',
    creationBlock: 9107268,
  },
}

export const UNIVERSAL_ROUTER_ADDRESS = (chainId: number): string => {
  if (!(chainId in CHAIN_CONFIGS)) throw new Error(`Universal Router not deployed on chain ${chainId}`)
  return CHAIN_CONFIGS[chainId].router
}

// Networks that share most of the same addresses i.e. Mainnet, Goerli, Optimism, Arbitrum, Polygon
// const DEFAULT_ADDRESSES: ChainAddresses = {
//     v3CoreFactoryAddress: '0x1F98431c8aD98523631AE4a59f267346ea31F984',
//     multicallAddress: '0x1F98415757620B543A52E61c46B32eB19261F984',
//     quoterAddress: '0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6',
//     v3MigratorAddress: '0xA5644E29708357803b5A882D272c41cC0dF92B34',
//     nonfungiblePositionManagerAddress: '0xC36442b4a4522E871399CD717aBDD847Ab11FE88'
// }

const DEFAULT_ADDRESSES: ChainAddresses = {
    v3CoreFactoryAddress: '0xA8d1f40F3fCA92Be18095F490bD8Cbc82D82E0A3',
    multicallAddress: '0x8efaB68B2068A078198e4DEC030db5d9ED9BF662',
    quoterAddress: '0x9fCe5Aa62af08bD3904c23bC8d5B63f9048882a6',
    v3MigratorAddress: '0x61651F9Ab03423eF8320174A66eB7E5703988F13',
    nonfungiblePositionManagerAddress: '0x5C83cb29E2fDb9A99403aF960e3B1dC367AE94c0',
    tickLensAddress: '0xD1941267bd409875D6CEb31278eA0358Ef721691',
    v1MixedRouteQuoterAddress: '0x1F30d3D5033b9EB8cd39C2d4FFC75D1d755AE729',
    swapRouter02Address: '0xB8999532E9e4495F8976EEb478ffe363b7De4C50'
}

const POLYGON_ADDRESSES: ChainAddresses = DEFAULT_ADDRESSES

export const CHAIN_TO_ADDRESSES_MAP: Record<SupportedChainsType, ChainAddresses> = {
    [ChainId.POLYGON]: POLYGON_ADDRESSES,
    [ChainId.POLYGON_MUMBAI]: POLYGON_ADDRESSES,
}

/* V3 Contract Addresses */
export const V3_CORE_FACTORY_ADDRESSES: AddressMap = {
    ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
      memo[chainId] = CHAIN_TO_ADDRESSES_MAP[chainId].v3CoreFactoryAddress
      return memo
    }, {})
}
  
export const V3_MIGRATOR_ADDRESSES: AddressMap = {
    ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
      const v3MigratorAddress = CHAIN_TO_ADDRESSES_MAP[chainId].v3MigratorAddress
      if (v3MigratorAddress) {
        memo[chainId] = v3MigratorAddress
      }
      return memo
    }, {})
}
  
export const MULTICALL_ADDRESSES: AddressMap = {
    ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
      memo[chainId] = CHAIN_TO_ADDRESSES_MAP[chainId].multicallAddress
      return memo
    }, {})
}
  
export const QUOTER_ADDRESSES: AddressMap = {
    ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
      memo[chainId] = CHAIN_TO_ADDRESSES_MAP[chainId].quoterAddress
      return memo
    }, {})
}
  
export const NONFUNGIBLE_POSITION_MANAGER_ADDRESSES: AddressMap = {
    ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
      const nonfungiblePositionManagerAddress = CHAIN_TO_ADDRESSES_MAP[chainId].nonfungiblePositionManagerAddress
      if (nonfungiblePositionManagerAddress) {
        memo[chainId] = nonfungiblePositionManagerAddress
      }
      return memo
    }, {})
}

export const TICK_LENS_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const tickLensAddress = CHAIN_TO_ADDRESSES_MAP[chainId].tickLensAddress
    if (tickLensAddress) {
      memo[chainId] = tickLensAddress
    }
    return memo
  }, {})
}
  

export const MIXED_ROUTE_QUOTER_V1_ADDRESSES: AddressMap = SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
  const v1MixedRouteQuoterAddress = CHAIN_TO_ADDRESSES_MAP[chainId].v1MixedRouteQuoterAddress
  if (v1MixedRouteQuoterAddress) {
    memo[chainId] = v1MixedRouteQuoterAddress
  }
  return memo
}, {})

export const SWAP_ROUTER_02_ADDRESSES = (chainId: number) => {
  return '0xB8999532E9e4495F8976EEb478ffe363b7De4C50'
}