export enum ChainId {
    POLYGON = 137,
    POLYGON_MUMBAI = 80001
  }
  
  export const SUPPORTED_CHAINS = [
    ChainId.POLYGON,
    ChainId.POLYGON_MUMBAI,
  ] as const
  export type SupportedChainsType = typeof SUPPORTED_CHAINS[number]
  
  export enum NativeCurrencyName {
    MATIC = 'MATIC'
  }